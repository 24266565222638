$(function(){

	//アコーディオン
	$('.accordion-btn').click(function(){
		$(this).toggleClass('active');
		$(this).next('.accordion-body').slideToggle();
	});

	//スムーズスクロール
  $('a[href^="#"]').click(function(){
    var adjust = 0;
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top + adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

	//背景変更
	$(window).on("load scroll resize", function(){
		var page_h = $(document).height();
		var window_h = window.innerHeight;
		var block_h = (page_h - window_h) / 3;
		var position = $(window).scrollTop();
		var showClass = "show";
		if(position < block_h) {
			$('.bg-cover').not('.bg-img01').removeClass(showClass);
			$('.bg-img01').addClass(showClass);
		}
		else if(position <= block_h * 2){
			$('.bg-cover').not('.bg-img02').removeClass(showClass);
			$('.bg-img02').addClass(showClass);
		}
		else if(position <= block_h * 3){
			$('.bg-cover').not('.bg-img03').removeClass(showClass);
			$('.bg-img03').addClass(showClass);
		}
	});

});


